import styled from 'styled-components';
import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import { spacing, radius, breakpoints } from '@scriptscouts/react-platform-client/src/utility/measurements';
import { Heading } from '@primer/react';

export const StyledFooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spacing['2xl']};
  background: ${colors['surfaceBrandDark']};
  color: ${colors['textInvertedPrimary']};
  padding: ${spacing['2xl']} ${spacing['lg']} ${radius['md']} ${spacing['lg']};
  font-family: Manrope;
  font-size: 16px;
  .h3, p {
    padding: 0;
    margin: 0;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing['md']};
  width: 100%;
  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledCompanyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  @media only screen and (min-width: ${breakpoints['sm']}) {
    flex-direction: row;
  }
`;

export const StyledProcessContainer = styled.div`
  width: 100%;
  div {
    width: 100%;
    gap: ${spacing['md']};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0.8;
    p {
      margin: 10px 0;
    }
  }
  button {
    align-self: stretch;
  }

  @media only screen and (min-width: ${breakpoints['sm']}) {
    min-width: 249px;
    button {
      align-self: flex-start;
    }
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    min-width: 194px;
  }
  @media only screen and (min-width: ${breakpoints['xl']}) {
    min-width: 290px;
  }
`;

export const StyledCompanyContainer = styled.div`
  width: 100%;
  div {
    width: 100%;
    gap: ${spacing['md']};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0.8;
    p {
      margin: 10px 0;
    }
  }
  @media only screen and (min-width: ${breakpoints['sm']}) {
    min-width: 249px;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 194px;
  }
  @media only screen and (min-width: ${breakpoints['xl']}) {
    min-width: 290px;
  }
`;

export const StyledDisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  div > p {
    opacity: 0.6;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
  @media only screen and (min-width: ${breakpoints['sm']}) {
    gap: ${spacing['md']};
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    gap: ${spacing['xl']};
    max-width: 497px;
  }
  @media only screen and (min-width: ${breakpoints['xl']}) {
    max-width: 621px;
  }
  @media only screen and (min-width: ${breakpoints['2xl']}) {
    max-width: 800px;
  }
`;

export const StyledSocialMediaLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;

export const StyledSocialMediaLogo = styled.img``;

export const StyledHeading = styled(Heading)`
  color: ${colors['textInvertedPrimary']};
  font-weight: 700;
  line-height: 150%; /* 24px */
  @media only screen and (min-width: ${breakpoints['lg']}) {
    font-size: 18px;
    margin: 0 0 ${spacing['lg']} 0;
  }
`;

export const StyledCopyrightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  opacity: 0.8;
  font-size: 12px;
  @media only screen and (min-width: ${breakpoints['md']}) {
    align-items: center;
    justify-content: space-between;
    padding: ${spacing['sm']} 0px;
  }
  @media only screen and (min-width: ${breakpoints['lg']}) {
    justify-content: space-around;
    align-items: center;
  }
`;

export const StyledCopyrightLinksContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  @media only screen and (min-width: ${breakpoints['sm']}) {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
  }
  @media only screen and (min-width: ${breakpoints['lg']}) {
    max-width: 560px;
    align-items: flex-start;
    gap: ${spacing['2xl']};
  }
  @media only screen and (min-width: ${breakpoints['xl']}) {
    max-width: 580px;
  }
`;

export const StyledSocialMediaContainer = styled.div`
  width: 100%;
  display: none;
  
  @media only screen and (min-width: ${breakpoints['md']}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 299px;
  }
`;
