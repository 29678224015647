/* eslint-disable max-len */

export default {
  scriptScouts                   : 'ScriptScouts',
  scriptScoutsProcess            : 'ScriptScouts process',
  howItWorks                     : 'How it works',
  membershipBenefits             : 'Membership benefits',
  becomeAMember                  : 'Become a member',
  ourCompany                     : 'Our company',
  login                          : 'Log in',
  logout                         : 'Logout',
  aboutScriptScouts              : 'About ScriptScouts',
  contactUs                      : 'Contact Us',
  frequentlyAskedQuestions       : 'Frequently Asked Questions',
  privacy                        : 'Privacy',
  privacyDisclaimer              : 'We don’t share your personal information or medication list. Ever. With anyone. Rest assured that your information is kept confidential and cared for with high security standards.',
  noteToOurCustomers             : 'Note to our customers',
  noteToOurCustomersDescription  : 'We’ve built ScriptScouts to work in the United States. Prices listed are in USD. If you have a need for prescription drug savings in a different country, feel free to reach out to our team.',
  privacyPolicy                  : 'Privacy Policy',
  termsOfUse                     : 'Terms of Use',
  joinPremium                    : 'Join ScriptScouts Premium',
  joinPremiumDescription         : 'Want to save the maximum amount on your prescriptions? Join ScriptScouts Premium Today and Unlock Exclusive Benefits.',
  processContainerHeader         : 'The ScriptScouts process',
  processContainerDescription    : 'We’ve perfected our process, ensuring you effortlessly access the savings you deserve for a healthier and more affordable life.',
  signupForPremiumToday          : 'Sign up for premium today',
  searchMedicationsTitle         : 'Search your medications',
  searchMedicationsDescription   : 'Effortlessly search multiple medications at one time and discover the best prescription discounts on the internet. ',
  comparePricesTitle             : 'Compare prices',
  comparePricesDescription       : 'Compare prescription discounts from multiple sources to find the best price that suits you, either from multiple locations or just one!',
  retrieveDiscountsTitle         : 'Retrieve your discounts',
  retrieveDiscountsDescription   : 'Easily text, email, share, or print your coupons so that you have them easily accessible when it’s time to pick up your prescriptions.',
  searchDiscountsHeader          : 'Search prescription drug discounts',
  searchDiscountsDescription     : 'Find the best overall price on your prescription drugs.',
  searchDiscountsPlaceholder     : 'Search prescription drugs',
  upgradeToPremiumTitle          : 'Upgrade to Premium for Additional Benefits',
  upgradeToPremiumDescription    : 'Save and track all your prescriptions in one place. Let us transfer your prescriptions for you so you can save both time and money.  Set your preferred pharmacy so that you can pick up all your prescriptions in one place. ',
  zipCodePlaceholder             : 'Zip code',
  addPrescription                : 'Add prescription',
  providersDisplayHeader         : 'Bringing you the best prices from:',
  reviewContainerHeader          : 'What customers are saying about ScriptScouts',
  rachelSReview                  : 'We saved over $50 per pickup on my son’s Adderall prescription. I didn’t realize the price variations between pharmacies were so high!',
  scriptScoutsBenefits           : 'ScriptScouts benefits',
  otherCompanies                 : 'Other Companies',
  free                           : 'Free',
  premium                        : 'Premium',
  signupForPremium               : 'Sign up for Premium',
  signupForFree                  : 'Sign up for free',
  monthShorthand                 : 'mo',
  findDiscounts                  : 'Find prescription discounts',
  searchMultipleProviders        : 'Search multiple providers',
  searchMultiplePrescriptions    : 'Search multiple prescriptions at a time',
  findOverAllPriceMultipleDrugs  : 'Find best overall price for multiple drugs',
  savePreferredPharmacy          : 'Save preferred pharmacy',
  trackPricesForMultipleDrugs    : 'Track best price for multiple drugs',
  transferPrescriptions          : 'Transfer prescriptions to different pharmacies',
  prescriptionSearch             : 'Prescription search',
  noSearchResults                : 'No prescription medications found',
  loadingPrices                  : 'We’re retrieving the best prices from over 300 pharmacies.',
  prescriptionDetails            : 'Prescription details',
  prescriptionUpdateInfo         : 'Updating your prescription may change which pharmacies honor the best prices.',
  editPrescription               : 'Edit prescription',
  of                             : 'of',
  prescriptionResultsHeader      : 'Your prescription discount results',
  prescriptionResultsSubHeader   : 'Here are the best prices based on the prescriptions you searched. ScriptScouts searched both online and in-person pharmacies.',
  totalWithScriptScouts          : 'ScriptScouts Powered Discount Search Results',
  pharmaciesSearched             : 'Over 300 pharmacies searched.',
  totalWithoutScriptScouts       : 'What Big Pharma Wants You To Pay',
  saveMoreUsingOneService        : 'If you choose to pay full price',
  retailPrice                    : 'Retail price we are seeing:',
  scriptScoutsPrice              : 'Lowest price we found:',
  findPrices                     : 'Find prices',
  signupToSave                   : 'Signup to save',
  additionalPriceOptions         : 'Additional pharmacy price options',
  retail                         : 'Retail',
  retailPercentageLabel          : 'Save %s when you sign up',
  selectForm                     : 'Select form',
  selectDosage                   : 'Select dosage',
  selectQuantity                 : 'Select quantity',
  form                           : 'Form',
  dosage                         : 'Dosage',
  quantity                       : 'Quantity',
  updatePrescription             : 'Update prescription',
  cancel                         : 'Cancel',
  selectPharmacyOption           : 'Select a preferred pharmacy to get coupon details.',
  textCoupon                     : 'Text coupon',
  emailCoupon                    : 'E-mail coupon',
  providedBy                     : 'Provided by',
  cheapestPrice                  : 'Cheapest price',
  bin                            : 'BIN',
  pcn                            : 'PCN',
  group                          : 'Group',
  memberId                       : 'Member ID',
  prescriptionSavingsHeader      : 'See all your prescriptions in one place and set your preferred pharmacy.',
  prescriptionSavingsButton      : 'My prescription savings',
  loading                        : 'Loading...',
  thisIsNotInsurance             : 'This is not insurance',
  pricingErrorMessage            : 'Something went wrong while fetching prices for %s. Please try again later.',
  medicationAdjustment           : 'Remove from my Prescription savings',
  searchRadius                   : 'Search radius',
  mile                           : 'mile',
  miles                          : 'miles',
  enterValidZipCode              : 'Enter a valid zip code',
  enterAtLeastOneMedication      : 'Enter at least one medication',
  medicationError                : 'cannot be loaded',
  medicationUnavailable          : 'Sorry, we weren’t able to find your medication. Try searching again or reach out to ',
  contactSupportMessage          : 'to request that we add a specific medication or pharmacy to our database. Thanks!',
  supportLink                    : 'support@scriptscouts.com ',
  viewOnlinePharmacyCoupon       : 'Visit online pharmacy',
  selectedPharmacy               : 'Selected pharmacy',
  medication                     : 'Medication',
  externalPharmacyDisclaimer     : 'You will be redirected to an external pharmacy website and may need to re-enter some information.',
  externalRedirectHeader         : 'You are being redirected to an external website.',
  externalRedirectMessage        : 'You will be redirected to an external website in %s seconds. Please click on the link below if you are not automatically redirected.',
  contactUsMessage               : 'We\'d love to hear from you! Whether you have questions, feedback, or want to partner with us, we\'re here to help.',
  firstName                      : 'First Name',
  enterFirstName                 : 'Enter first name',
  firstNameIsRequired            : 'First Name is required',
  firstNameValidationError       : 'First Name is invalid',
  lastName                       : 'Last Name',
  enterLastName                  : 'Enter last name',
  lastNameValidationError        : 'Last Name is invalid',
  lastNameIsRequired             : 'Last Name is required',
  email                          : 'E-mail',
  enterEmail                     : 'Enter email',
  emailValidationError           : 'Email is invalid',
  emailIsRequired                : 'Email Name is required',
  reasonContact                  : 'Reason for contact',
  selectOne                      : 'Select one',
  reasonContactValidationError   : 'Reason for contact is invalid',
  reasonContactIsRequired        : 'Reason for contact is required',
  subject                        : 'Subject',
  enterSubject                   : 'Enter subject',
  subjectIsRequired              : 'Subject is required',
  subjectValidationError         : 'Subject Name is invalid',
  message                        : 'Message',
  enterMessage                   : 'Enter message...',
  messageIsRequired              : 'Message is required',
  messageValidationError         : 'Message is invalid',
  faqs                           : 'Frequently asked questions',
  whatsMission                   : 'What is ScriptScouts\' mission?',
  whatsMissionDescription        : 'We\'re on a mission to help people get their prescription drugs that are often life-saving at the lowest price.',
  howDoesItWork                  : 'How does ScriptScouts work?',
  howDoesItWorkDescription       : 'Anyone can sign up online, enter in the prescriptionI(s) you need, and get updates on prices, discount codes, at their local or online pharmacy.',
  isMyInfoShared                 : 'Does ScriptScouts share my information with 3rd party platforms?',
  isMyInfoSharedDescription      : 'We don\'t share your personal information or medication list. Ever. With anyone. Rest assured that your information is kept confidential and cared for with high security standards.',
  isItInsurance                  : 'Is ScriptScouts health insurance?',
  isItInsuranceDescription       : 'No. ScriptScouts is simply a software based search engine. ScriptScouts searches multiple sources and reputable manufacturer\'s coupons to reduce your manual efforts.',
  searchInsurance                : 'Does ScriptScouts search health insurance prices?',
  searchInsuranceDescription     : 'The sources it searches are not based on health insurance either - the discounts and prices the search engine finds is based on “cash pay” or out-of-pocket prices for medications.',
  diffWithProvider               : 'What is the difference between ScriptScouts and other Rx coupon code websites?',
  diffWithProviderDescription    : 'ScriptScouts is a search engine of the prescription drug market. It searches sources from across the web to determine the best price and pickup location for your medications. Sources include prescription drug coupon providers and online pharmacies, like SingleCare and CostPlusDrugs.',
  subscriptions                  : 'Subscriptions',
  needInsurance                  : 'Do I need health insurance to have a subscription?',
  needInsuranceDescription       : 'No, we search “cash pay” prices which will help you get the lowest prices regardless of your insurance coverage.',
  howToCancel                    : 'How do I cancel my subscription?',
  howToCancelDescriptionSection1 : 'If you need to cancel your subscription, please contact us at ',
  howToCancelDescriptionSection2 : ' and we will assist you. Even after canceling, you can continue using the subscription features until the end of your subscription period. After your license expires, you will still have access to ScriptScouts.com with the features available in the free tier. Please note that free tier features may change over time. If you need to permanently delete your account, simply mention that in your email and we’ll work with you to ensure all your information is safely and securely deleted from our systems.',
  general                        : 'General',
  whatIsCashPay                  : 'What is cash pay?',
  whatIsCashPayDescription       : 'Cash pay is when a prescription holder literally “pays cash” for a medication instead of going through insurance. Often times, cash pay prices beat out insurance prices.',
  howOftenPriceChange            : 'How often are prescription drug prices changing?',
  howOftenPriceChangeDescription : 'In recent research, prices can vary daily.',
  whatIsNADAC                    : 'What is NADAC?',
  whatIsNADACDescription         : 'NADAC stands for “National Average Drug Acquisition Cost.” It is a pricing benchmark created and maintained by the Centers for Medicare & Medicaid Services (CMS). NADAC represents the average price at which retail community pharmacies and long-term care (LTC) pharmacies purchase prescription drugs from wholesalers. This benchmark is updated weekly, providing an up-to-date reflection of drug prices.',
  whatIsPriorAuth                : 'What is Prior Authorization?',
  whatIsPriorAuthDescription     : 'Prior authorization is a cost-control process used by health insurance companies where they require healthcare providers to obtain approval before performing a service, prescribing a medication, or providing a treatment to ensure it is covered under a patient’s health insurance plan. This means that before certain medications can be dispensed or treatments provided, the insurance company must first review and agree that the procedure or medication is medically necessary.',
  theScriptScoutsProcess         : 'The ScriptScouts process',
  comparePrescPricesTitle        : 'Compare prescription prices',
  asOfDate                       : '(as of %s)',
  thankYou                       : 'Thank you for becoming a ScriptScouts member!',
  thankYouCountdown              : 'This window will close automatically in',
  seconds                        : 'seconds.'
};
/* eslint-enable max-len */
