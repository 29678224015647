import React, { useContext } from 'react';

import { Link } from '@scriptscouts/react-platform-client/src/components/common';
import {
  StyledNavBar,
  StyledLinksContainer,
  StyledMenuContainer,
  StyledRightAlignedContainer,
  StyledHamburgerMenu,
  StyledActionMenu,
  StyledLoginButton,
  StyledLogo
} from './style';

import { i18n } from '../../utils/i18n';

import { logout, inferTenancyFromDomain } from '@scriptscouts/react-platform-client/src/utility/services/Auth';
import { AuthContext } from '@scriptscouts/react-platform-client/src/context/AuthContext';

import HamburgerMenu from '../../Assets/Icons/hamburger-menu.svg';

import { ActionList } from '@primer/react';

const Navigation = () => {
  const { loggedIn } = useContext(AuthContext);
  const { domain } = inferTenancyFromDomain();

  const loginOnClick = () => {
    if (!loggedIn) {
      window.location.assign(`${window.CX.AUTH_GATE_WEB_ROOT_URL(domain)}/?redirectTo=${encodeURIComponent(window.location.href)}`);
    }
  };

  return (
    <StyledNavBar>
      <Link href={window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}>
        <StyledLogo
          src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
            path      : 'logos/scriptscouts-transparent',
            extension : 'svg',
            options   : ['width=180']
          })}`}
          alt="ScriptScouts Logo"
        />
      </Link>
      <StyledLinksContainer>
        <Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/how-it-works`}>{i18n('howItWorks')}</Link>
        <Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/membership-benefits`}>{i18n('membershipBenefits')}</Link>
        <Link href={window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}>{i18n('prescriptionSearch')}</Link>
      </StyledLinksContainer>
      <StyledRightAlignedContainer>
        {
          loggedIn
            ? <StyledLoginButton type="secondary" onClick={() => logout(`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}`)}>{i18n('logout')}</StyledLoginButton>
            : <StyledLoginButton type="secondary" onClick={loginOnClick}>{i18n('login')}</StyledLoginButton>
        }
        <StyledMenuContainer>
          <StyledActionMenu>
            <StyledActionMenu.Button variant="invisible" id="hamburger-menu-button">
              <StyledHamburgerMenu src={HamburgerMenu} alt='' loading='lazy' />
            </StyledActionMenu.Button>
            <StyledActionMenu.Overlay>
              <ActionList>
                <Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/how-it-works`}>
                  <ActionList.Item>{i18n('howItWorks')}</ActionList.Item>
                </Link>
                <Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/membership-benefits`}>
                  <ActionList.Item>{i18n('membershipBenefits')}</ActionList.Item>
                </Link>
                <Link href={window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}>
                  <ActionList.Item>{i18n('prescriptionSearch')}</ActionList.Item>
                </Link>
                <Link href={`${window.CX.AUTH_GATE_WEB_ROOT_URL(domain)}/?redirectTo=${encodeURIComponent(window.location.href)}`}>
                  <ActionList.Item>{i18n('login')}</ActionList.Item>
                </Link>
              </ActionList>
            </StyledActionMenu.Overlay>
          </StyledActionMenu>
        </StyledMenuContainer>
      </StyledRightAlignedContainer>
    </StyledNavBar>
  );
};

export default Navigation;
