import React             from 'react';
import { ThemeProvider } from '@primer/react';
import PropTypes         from 'prop-types';

import { TransferConsumer }     from '@scriptscouts/react-platform-client/src/components/appTransfers';
import { AuthProvider }         from '@scriptscouts/react-platform-client/src/context/AuthContext';
import { NotificationProvider } from '@scriptscouts/react-platform-client/src/context/NotificationContext';
import { SeoProvider }          from '@scriptscouts/react-platform-client/src/context/SeoContext';
import { MedicationProvider }   from './Context/MedicationContext';

const AppProviders = ({ children }) => {
  return (
    <TransferConsumer>
      <SeoProvider>
        <NotificationProvider>
          <AuthProvider>
            <MedicationProvider>
              <ThemeProvider>
                {children}
              </ThemeProvider>
            </MedicationProvider>
          </AuthProvider>
        </NotificationProvider>
      </SeoProvider>
    </TransferConsumer>
  );
};

AppProviders.propTypes = {
  children : PropTypes.node
};

export default AppProviders;
