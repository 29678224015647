import styled from 'styled-components';
// import Button from '../CommonComponents/Button';
import { Heading } from '@primer/react';

import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  radius,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

export const StyledContainer = styled.div`
  background: ${colors['surfaceBase']};
  color: ${colors['textPrimary']};
  display: flex;
  padding: ${spacing['xl']};
  flex-direction: column;
  align-items: center;
  border-radius: ${radius['lg']};
  margin: 0 ${spacing['xl']};;
  gap: ${spacing['xl']};
  align-self: stretch;
  text-align: center;
  font-family: Manrope;
  font-style: normal;
  line-height: 125%;
  box-shadow: 0px 78px 22px 0px rgba(43, 115, 174, 0.00),
    0px 50px 20px 0px rgba(43, 115, 174, 0.01),
    0px 28px 17px 0px rgba(43, 115, 174, 0.02),
    0px 12px 12px 0px rgba(43, 115, 174, 0.03),
    0px 3px 7px 0px rgba(43, 115, 174, 0.04);
`;

export const StyledHeader = styled(Heading)`
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%; /* 30px */
  margin: 0;
`;

export const StyledProvidersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${spacing['lg']};
  align-self: center;
  max-width: 328px;
  img {
    max-height: ${spacing['xl']};
  }

  @media only screen and (min-width: ${breakpoints['sm']}) {
    max-width: 528px;
  }

  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 100%;
  }
`;
