import * as R from 'ramda';
import currency from 'currency.js';
import { i18n } from './i18n';

export const parseTimestamp = (timestamp) => {
  const [dateString, offsetString] = timestamp.split('T');
  const dateParts = dateString.split('-');
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  const day = parseInt(dateParts[2], 10);
  const offsetParts = offsetString.slice(0, -6).split(':');
  const offsetHours = parseInt(offsetParts[0], 10);
  const offsetMinutes = parseInt(offsetParts[1], 10);
  const date = new Date(year, month, day, offsetHours, offsetMinutes);
  return date.toLocaleString();
};

export const roundNumberToTwoDecimals = value => {
  const roundedNumber = Math.round(value * 100) / 100;
  return roundedNumber.toFixed(2);
};

export const percentage = (retailPrice, listPrice) => {
  try {
    return roundNumberToTwoDecimals(((listPrice - retailPrice) / (listPrice)) * 100);
  } catch {
    return 0;
  }
};

export const penniesToDollars = ({ precision }) => cents => currency(cents, { fromCents : true, precision })?.value?.toFixed(precision);

export const USD = value => currency(value, { symbol : '$', precision : 2 }).format();

export const sortByProp = prop => R.sort((a, b) => a[prop] - b[prop]);

export const ensureArrayResponse = R.unless(
  R.pipe(R.type, R.equals('Array')),
  R.always([])
);

export const maybeSplitFrom = R.compose(
  R.defaultTo([]),
  R.split(','),
  R.defaultTo('')
);

export const capitalizeFirstLetter = R.converge(
  R.concat,
  [R.compose(R.toUpper, R.head), R.tail]
);

export const SEARCH_PROXIMITY_RADIUS_OPTIONS = [
  {
    name : `1 ${i18n('mile')}`,
    id   : 1
  },
  {
    name : `5 ${i18n('miles')}`,
    id   : 5
  },
  {
    name : `10 ${i18n('miles')}`,
    id   : 10
  },
  {
    name     : `15 ${i18n('miles')}`,
    id       : 15,
    selected : true
  },
  {
    name : `20 ${i18n('miles')}`,
    id   : 20
  }
];

export const SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED_INDEX = R.findIndex(
  R.prop('selected')
)(SEARCH_PROXIMITY_RADIUS_OPTIONS);

export const SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED = SEARCH_PROXIMITY_RADIUS_OPTIONS[
  SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED_INDEX];
