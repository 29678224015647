import React  from 'react';
import * as R from 'ramda';

import { inferTenancyFromDomain } from '@scriptscouts/react-platform-client/src/utility/services/Auth';
import { Button, Link } from '@scriptscouts/react-platform-client/src/components/common';

import {
  StyledContainer,
  StyledHeader,
  StyledSignupButtonFillerComponent,
  StyledPricingCardsContainer,
  StyledPricingCardContainer,
  StyledPricingCardHeader,
  StyledPricingCardPrice,
  StyledPricingCardFeatures,
  StyledCheckboxIcon,
  StyledCrossIcon
} from './style';

import { i18n } from '../../utils/i18n';

import PRICING_PLANS from './pricingPlans.json';

const PricingComponent = () => {
  const { domain } = inferTenancyFromDomain();

  const maybeAddHighlightClass = plan => plan?.highlight ? 'highlight' : '';

  const renderPricingCards = () => {
    return PRICING_PLANS.map(plan => (
      <StyledPricingCardContainer key={`${plan.id}-pricing-card`} className={maybeAddHighlightClass(plan)}>
        <StyledPricingCardHeader>
          { i18n(plan.label) }
          <StyledPricingCardPrice>
            {
              plan?.price && plan?.currency
                ? <span>{plan.currency}{i18n(plan.price)}<span className='monthShorthand'>/{i18n('monthShorthand')}</span></span>
                : <span></span>
            }
          </StyledPricingCardPrice>
        </StyledPricingCardHeader>
        <StyledPricingCardFeatures>
          {
            R.values(R.mapObjIndexed((val, key) => {
              const IconImage = val ? StyledCheckboxIcon : StyledCrossIcon;
              return (
                <p key={`${plan.id}-pricing-card-${key}`}>
                  {
                    <IconImage
                      className={`${plan.highlight ? 'primary': ''}`}
                      alt=''
                    />
                  }
                  <span className={`${val ? '' : 'strikethrough'} ${plan.highlight ? 'primary': ''}`}>{i18n(key)}</span>
                </p>
              );
            },plan.features))
          }
        </StyledPricingCardFeatures>
        {
          plan?.buttonLabel
            ? <Link href={`${window.CX.AUTH_GATE_WEB_ROOT_URL(domain)}/signup`}>
              <Button type={plan?.highlight ? 'primary' : 'tertiary'}>{i18n(plan?.buttonLabel)}</Button>
            </Link>
            : <StyledSignupButtonFillerComponent />
        }
      </StyledPricingCardContainer>
    ));
  };

  return (
    <section id="pricing">
      <StyledContainer>
        <StyledHeader as="h2">{i18n('scriptScoutsBenefits')}</StyledHeader>
        <StyledPricingCardsContainer>
          { renderPricingCards() }
        </StyledPricingCardsContainer>
      </StyledContainer>
    </section>
  );
};

export default PricingComponent;
