import styled from 'styled-components';
import { CheckCircleFillIcon, XCircleIcon } from '@primer/octicons-react';

import { Heading } from '@primer/react';

import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  breakpoints,
  radius
} from '@scriptscouts/react-platform-client/src/utility/measurements';

export const StyledContainer = styled.div`
  color: ${colors['textPrimary']};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing['2xl']};
  align-self: stretch;
  margin-bottom: ${spacing['4xl']};
  width: 100%;
  text-align: center;
  font-family: Manrope;
`;

export const StyledHeader = styled(Heading)`
  color: ${colors['textPrimary']};
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  margin: 0;
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 30px;
  }
`;

export const StyledPricingCardsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  overflow-x: hidden;
  overflow-y: auto;

  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  padding: 30px 0;
  width: 100%;

  // Hide scrollbar
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  :-webkit-scrollbar {
  display: none;
}

.highlight {
  background: ${colors['surfaceBrandDark']};
  color: ${colors['textInvertedPrimary']};
}

@media only screen and (min-width: ${breakpoints['md']}) {
  flex-direction: row;
  justify-content: center;
}
`;

export const StyledPricingCardContainer = styled.div`
  display: flex;
  padding: ${spacing['lg']};
  flex-direction: column;
  align-items: center;
  gap: ${spacing['xl']};
  border-radius: ${radius['lg']};
  background: ${colors['surfaceBase']};
  min-width: 240px;
  max-width: 294px;
  line-height: 150%;
  box-shadow: 0px 78px 22px 0px rgba(43, 115, 174, 0.00),
    0px 50px 20px 0px rgba(43, 115, 174, 0.01),
    0px 28px 17px 0px rgba(43, 115, 174, 0.02),
    0px 12px 12px 0px rgba(43, 115, 174, 0.03),
    0px 3px 7px 0px rgba(43, 115, 174, 0.04);
`;

export const StyledPricingCardHeader = styled.div`
  color: ${colors['textInvertedSecondary']};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

export const StyledPricingCardPrice = styled.div`
  min-height: 45px;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  .monthShorthand {
    font-size: 24px;
  }
`;

export const StyledSignupButtonFillerComponent = styled.div`
  min-height: 53px;
`;

export const StyledPricingCardFeatures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  p {
    display: flex;
    align-items: flex-start;
    gap: var(--Spacing-md, 8px);
    margin: 0;
  }
  .strikethrough {
    text-decoration: line-through;
    color: ${colors['textMuted']};
  }
  .strikethrough.primary {
   color: ${colors['textInvertedPrimary']};
  }

`;

export const StyledCheckboxIcon = styled(CheckCircleFillIcon)`
  margin-right: ${spacing['sm']};
  padding-top: 5px;
  color: ${colors['surfacePositive']};
`;

export const StyledCrossIcon = styled(XCircleIcon)`
  margin-right: ${spacing['sm']};
  padding-top: 5px;
  color: ${colors['textLightMuted']};
`;
