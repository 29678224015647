import React, { lazy, Suspense } from 'react';
import { Spinner } from '@primer/react';
import { ErrorBoundary } from 'react-error-boundary';
import { PageLayout } from '@primer/react';
import EnsureAuthorized from '@scriptscouts/react-platform-client/src/components/authGate/EnsureAuthorized';

import AppProviders from './AppProviders';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import Landing from './Pages/Landing';

const Gated = lazy(() => import('./Pages/Gated'));
const MedicationPrices = lazy(() => import('./Pages/MedicationPrices'));
const ThankYou = lazy(() => import('./Pages/ThankYou'));
const Checkout = lazy(() => import('./Pages/Checkout'));
const CheckoutReturn = lazy(() => import('./Pages/CheckoutReturn'));
const ContactUs = lazy(() => import('./Pages/ContactUs'));
const FAQ = lazy(() => import('./Pages/FAQ'));
const HowItWorks = lazy(() => import('./Pages/HowItWorks'));
const MembershipBenefits = lazy(() => import('./Pages/MembershipBenefits'));
const ExternalRedirect = lazy(() => import('@scriptscouts/react-platform-client/src/components/common/ExternalRedirect'));

import { i18n } from './utils/i18n';

const App = () => {
  return (
    <AppProviders>
      {/* TODO (SCOUT-201) : Add a degraded experiance common component and import here for App level error boundary. */}
      <ErrorBoundary fallback={<div>Something went wrong</div>}> 
        <Suspense fallback={<Spinner size='large' className='suspense-spinner' />}>
          <Router basename="/rx">
            <PageLayout padding="none" columnGap="none" rowGap="none" containerWidth="full">
              <PageLayout.Header>
                <Navigation />
              </PageLayout.Header>
              <PageLayout.Content id="page-content">
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/redirect" element={<ExternalRedirect i18n={i18n} />} />
                  <Route path="/medication-prices" element={<MedicationPrices />} />
                  <Route path="/gated" element={<EnsureAuthorized element={Gated} />} />
                  <Route path="/thank-you" element={<ThankYou/>}  />
                  <Route path="/checkout" element={<EnsureAuthorized element={Checkout} />}  />
                  <Route path="/checkout/return" element={<EnsureAuthorized element={CheckoutReturn} />}  />
                  <Route path="/contact-us" element={<ContactUs />}  />
                  <Route path="/faq" element={<FAQ />}  />
                  <Route path="/how-it-works" element={<HowItWorks />}  />
                  <Route path="/membership-benefits" element={<MembershipBenefits />}  />
                </Routes>
              </PageLayout.Content>
              <PageLayout.Footer>
                <Footer />
              </PageLayout.Footer>
            </PageLayout>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </AppProviders>
  );
};

export default App;
