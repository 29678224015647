import React from 'react';
import { inferTenancyFromDomain } from '@scriptscouts/react-platform-client/src/utility/services/Auth';
import { Link, Button } from '@scriptscouts/react-platform-client/src/components/common';

import { i18n } from '../../utils/i18n';

import {
  StyledHeading,
  StyledSocialMediaLogo,
  StyledFooterContainer,
  StyledInfoContainer,
  StyledProcessContainer,
  StyledCompanyContainer,
  StyledCompanyInfoContainer,
  StyledDisclaimerContainer,
  StyledSocialMediaContainer,
  StyledCopyrightContainer,
  StyledCopyrightLinksContainer,
  StyledSocialMediaLogoContainer
} from './style';

const Footer = () => {
  const { domain } = inferTenancyFromDomain();

  return (
    <section id="footer">
      <StyledFooterContainer>
        <StyledInfoContainer>
          <StyledCompanyInfoContainer>
            <StyledProcessContainer>
              <StyledHeading as="h3">{i18n('scriptScoutsProcess')}</StyledHeading>
              <div>
                <p><Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/how-it-works`}>{i18n('howItWorks')}</Link></p>
                <p><Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/membership-benefits`}>{i18n('membershipBenefits')}</Link></p>
                <Link href={`${window.CX.AUTH_GATE_WEB_ROOT_URL(domain)}/signup`}>
                  <Button type="secondary">{i18n('becomeAMember')}</Button>
                </Link>
              </div>
            </StyledProcessContainer>
            <StyledCompanyContainer>
              <StyledHeading as="h3">{i18n('ourCompany')}</StyledHeading>
              <div>
                <p><Link href="https://scriptscouts.com/about" target="_blank">{i18n('aboutScriptScouts')}</Link></p>
                <p><Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/contact-us`}>{i18n('contactUs')}</Link></p>
                <p><Link href={`${window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}/faq`}>{i18n('frequentlyAskedQuestions')}</Link></p>
              </div>
            </StyledCompanyContainer>
          </StyledCompanyInfoContainer>
          <StyledDisclaimerContainer>
            <div>
              <StyledHeading as="h3">{i18n('privacy')}</StyledHeading>
              <p>{i18n('privacyDisclaimer')}</p>
            </div>
            <div>
              <StyledHeading as="h3">{i18n('noteToOurCustomers')}</StyledHeading>
              <p>{i18n('noteToOurCustomersDescription')}</p>
            </div>
          </StyledDisclaimerContainer>
        </StyledInfoContainer>
        <StyledCopyrightContainer>
          <StyledCopyrightLinksContainer>
            <p><Link href={window.CX.PILL_PROXY_WEB_ROOT_URL(domain)}>&copy; {new Date().getFullYear()} {i18n('scriptScouts')}</Link></p>
            <p><Link href="https://scriptscouts.com/privacy-policy" target="_blank">{i18n('privacyPolicy')}</Link></p>
            <p><Link href="https://scriptscouts.com/terms-of-service" target="_blank">{i18n('termsOfUse')}</Link></p>
          </StyledCopyrightLinksContainer>
          <StyledSocialMediaContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://www.linkedin.com/company/scriptscouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/linkedin',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="LinkedIn"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://www.instagram.com/scriptscouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/instagram',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="Instagram"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://www.facebook.com/scriptscouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/facebook',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="Facebook"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://www.youtube.com/@ScriptScouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/youtube',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="Youtube"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://www.tiktok.com/@scriptscouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/tiktok',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="Tiktok"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://twitter.com/scriptscouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/x',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="X / Twitter"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
            <StyledSocialMediaLogoContainer>
              <Link href="https://www.pinterest.com/scriptscouts" target="_blank">
                <StyledSocialMediaLogo
                  src={`${window.CX.STATIC_IMAGE_ASSETS_URL({
                    path      : 'logos/social/pinterest',
                    extension : 'svg',
                    options   : ['width=32']
                  })}`}
                  alt="Pinterest"
                  loading='lazy'
                />
              </Link>
            </StyledSocialMediaLogoContainer>
          </StyledSocialMediaContainer>
        </StyledCopyrightContainer>
      </StyledFooterContainer>
    </section>
  );
};

export default Footer;
